/* eslint-disable */
import axios from 'axios'
import { showMessage } from 'utils/index'
import { userLogout } from 'pages/myutils/user'
const options = {
  headers: {
    'Cache-Control': 'no-cache'
  }
}
options.baseURL ="/api"
// if (process.env.REACT_APP_CURRENT === 'test') {
//   options.baseURL = 'http://test.qyconsult.treeyee.com/'
// } else if (process.env.NODE_ENV === 'production') {
//   options.baseURL = 'https://sw.treeyee.com/api/'
// }


window.axios = axios.create(Object.assign(options, {
  validateStatus: function (status) {
    return status >= 200 && status <= 500
  },
}))

window.axios.interceptors.request.use(function (config) {
  config.params ? (config.params['t'] = Date.now()) : (config.params = { t: Date.now() })
  return config
})
const location = window.location
window.axios.interceptors.response.use(function (response) {
  // 对响应数据做点什么
  // console.log('interceptors.response', response)
  if (response.status === 500 || response.status===401) {
    let toLogin = false
    if (response.data.message.indexOf("token")!==-1 || response.data.message === '请先登录') {
      toLogin = true
      // response.config.url !== "/customer/userInfo" && (toLogin = true)
      // if(location.href.indexOf("/login")===-1){
      //   response.data.message = '登录已失效，请重新登录'
      // }
    
      userLogout()
    } else if (response.data.message === '非企业管理员') {
      toLogin = true
    }
    // toLogin && (location.href = `/login`)
    setTimeout(() => {
      toLogin && window.location.href.indexOf("/login")===-1 && (location.href = `/login?reload=1&url=${encodeURIComponent(location.href)}`)
    }, 1000);
    response.data.message && response.data.message !== 'token 已经失效，请重新申请token' && showMessage('error', response.data.message)
  }
  return response;
});

let interceptorMap = new Map()

export function setRequestToken(token) {
  setRequestDefault('token', token)
  // // 添加请求拦截器
  // interceptorMap.set('token', window.axios.interceptors.request.use(function (config) {
  //   config.params ? (config.params.token = token) : (config.params = { token })
  //   return config
  // }))
}

export function removeRequestToken() {
  setRequestDefault('token', '')
  // window.axios.interceptors.request.eject(interceptorMap.get('token'));
  removeRequestDefault('token')
}

export function setRequestCompanyId(v) {
  const k = 'companyId'
  interceptorMap.set(k, window.axios.interceptors.request.use(function (config) {
    if (config.url.startsWith('/staff')) {
      config.params ? (config.params[k] = v) : (config.params = { [k]: v })
    }
    return config
  }))
}

export function removeRequestCompanyId() {
  removeRequestDefault('companyId')
}

function setRequestDefault(k, v) {
  interceptorMap.set(k, window.axios.interceptors.request.use(function (config) {
    config.params ? (config.params[k] = v) : (config.params = { [k]: v })
    return config
  }))
}

function removeRequestDefault(k) {
  window.axios.interceptors.request.eject(interceptorMap.get(k));
}
/* eslint-disable */
